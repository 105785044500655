/* ###### 4.4 Forms   ###### */

/* Checkbox */
.ckbox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 1;
  margin-bottom: 0;
  padding-left: 0;

  label {
    padding-left: 26px;

    &:empty { float: left; }

    &:before,
    &:after {
      line-height: 18px;
      position: absolute;
    }

    &:before {
      content: '';
      width: $ckbox-size;
      height: $ckbox-size;
      background-color: #fff;
      border: 1px solid $gray-500;
      top: 1px;
      left: 0;
    }

    &:after {
      top: 1px;
      left: 0;
      width: $ckbox-size;
      height: $ckbox-size;
      content: '';
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
      background-size: 65%;
      background-repeat: no-repeat;
      background-position: 55% 45%;
      background-color: theme-color('primary');
      line-height: 17px;
      display: none;
    }

    &:empty {
      padding-left: 0;
      width: 3px;
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    margin: 0;

    &:checked {
      + label:after { display: block; }
    }

    &[disabled] {
      + label,
      + label:before,
      + label:after {
        opacity: .75;
      }
    }
  }
}

.ckbox-inline { display: inline-block; }

/* Radio Box */
.rdiobox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 18px;

  label {
    padding-left: 13px;

    &:before,
    &:after {
      line-height: 18px;
      position: absolute;
    }

    &:before {
      content: '';
      width: $rdiobox-size;
      height: $rdiobox-size;
      background-color: #fff;
      border: 1px solid $gray-500;
      border-radius: 50px;
      top: 2px;
      left: 0;
    }

    &:after {
      content: '';
      width: $rdiobox-size - 10px;
      height: $rdiobox-size - 10px;
      background-color: #fff;
      border-radius: 50px;
      top: 7px;
      left: 5px;
      display: none;
    }

    &:empty {
      padding-left: 0;
      width: 16px;
      display: block;
    }
  }

  input[type='radio'] {
    opacity: 0;
    margin: 0;

    &:checked {
      + label:before {
        border-color: transparent;
        background-color: theme-color('primary');
      }
      + label:after { display: block; }
    }

    &[disabled] {
      + label,
      + label:before,
      + label:after {
        opacity: .75;
      }
    }
  }
}

.rdiobox-inline { display: inline-block; }

.form-group-rdiobox {
  display: flex;
  align-items: center;

  .rdiobox {
    margin-bottom: 0;

    + .rdiobox { margin-left: 30px; }

    span { padding-left: 0; }
  }
}

.az-toggle {
  width: 60px;
  height: 25px;
  background-color: $gray-400;
  padding: 2px;
  position: relative;
  overflow: hidden;

  span {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    display: block;
    width: 20px;
    background-color: #fff;
    cursor: pointer;
    @include transition($transition-base);

    &::before,
    &::after {
      position: absolute;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: .5px;
      text-transform: uppercase;
      color: #fff;
      top: 2px;
      line-height: 1.38;
    }

    &::before {
      content: 'on';
      left: -25px;
    }

    &::after {
      content: 'off';
      right: -29px;
    }
  }

  &.on {
    background-color: $az-color-primary;

    span { left: 37px; }
  }
}

.az-toggle-secondary.on { background-color: $blue; }
.az-toggle-success.on { background-color: $green; }
.az-toggle-dark.on { background-color: $gray-900; }


.az-form-group {
  padding: 12px 15px;
  border: 1px solid $input-border-color;

  &.focus {
    border-color: $gray-400;
    box-shadow: 0 0 0 2px rgba($az-color-primary, .16);
  }

  .form-label {
    font-size: $font-size-sm;
    margin-bottom: 2px;
    font-weight: 400;
    color: $gray-500;
  }

  .form-control {
    padding: 0;
    border-width: 0;
    height: 25px;
    color: $gray-900;
    font-weight: 500;

    &:focus { box-shadow: none; }
  }
}
