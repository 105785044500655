/* ###### 4.5 Image   ###### */

.az-img-user {
  display: block;
  position: relative;
  width: 36px;
  height: 36px;
  @include border-radius(100%);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include border-radius(100%);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 3px;
    width: 6px;
    height: 6px;
    background-color: $gray-500;
    box-shadow: 0 0 0 2px rgba(#fff, .95);
    @include border-radius(100%);
  }

  &.online::after {
    background-color: $green;
  }
}

.az-avatar {
  position: relative;
  width: 36px;
  height: 36px;
  @include border-radius(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  background-color: $gray-700;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 3px;
    width: 6px;
    height: 6px;
    background-color: $gray-500;
    box-shadow: 0 0 0 2px rgba(#fff, .95);
    @include border-radius(100%);
  }

  &.online::after { background-color: $green; }
}

.avatar-xs {
  width: 24px;
  height: 24px;
  font-size: 11px;

  &::after {
    width: 5px;
    height: 5px;
  }
}

.avatar-sm {
  width: 32px;
  height: 32px;
  font-size: 14px;

  &::after {
    width: 7px;
    height: 7px;
  }
}

.avatar-md {
  width: 48px;
  height: 48px;
  font-size: 24px;

  &::after {
    width: 9px;
    height: 9px;
    right: 2px;
    bottom: 2px;
  }
}

.avatar-lg {
  width: 64px;
  height: 64px;
  font-size: 28px;

  &::after {
    width: 10px;
    height: 10px;
    bottom: 3px;
    right: 4px;
  }
}

.avatar-xl {
  width: 72px;
  height: 72px;
  font-size: 36px;

  &::after {
    width: 11px;
    height: 11px;
    bottom: 4px;
    right: 5px;
    box-shadow: 0 0 0 2.5px #fff;
  }
}

.avatar-xxl {
  width: 100px;
  height: 100px;
  font-size: 56px;

  &::after {
    width: 13px;
    height: 13px;
    bottom: 6px;
    right: 7px;
    box-shadow: 0 0 0 3px #fff;
  }
}
